<template>
	<div class="frameCon">
	<el-row class="registerCon">
		<el-col :span="24">
			<div class="registerSuCon">
				<!-- <h2>{{$t('i18nn_158eb06e6609b0b7')}}</h2> -->
				<div class="registerSu">
					<h3>
						<!-- <i class="el-icon-circle-check"></i> -->
						<i class="icon_gou"></i>
						<span class="reg_msg"><span>{{$t('register.reg_success_1')}}</span>
							<span class="reg_phone" v-if="!!UserInfo">{{UserInfo.mobile||UserInfo.account}}</span>
							<span>{{$t('register.reg_success_2')}}</span>
						</span>
					</h3>

          <div class="way-list-tit">{{$t('register.reg_success_3')}}<i class="el-icon-bottom"></i><i class="el-icon-bottom"></i><i class="el-icon-bottom"></i></div>

          <ul class="way-list">

            <!-- <li @click="toPage('EnterpriseAuthentication')">
              <h3><i class="el-icon-right"></i>{{$t('i18nn_cb7e41fd1feae9d5')}}</h3>
              <p class="su_tip">认证完成后，您可以展示、推广、订购、销售、报价等</p>
            </li>
            <li @click="toPage('PartnerAuthentication')">
              <h3><i class="el-icon-right"></i>{{$t('i18nn_13fc2e4b355357dd')}}</h3>
              <p class="su_tip">认证完成后，您可以邀请厂家入驻平台、享受收益等</p>
            </li> -->



            <li @click="toPage('auth')">
              <h3><i class="el-icon-right"></i>{{$t('i18nn_87143dc9fc2e5b2f')}}</h3>
              <!-- <el-button type="primary" plain icon="el-icon-right" @click="toPage('EnterpriseAuthentication')">{{$t('i18nn_87143dc9fc2e5b2f')}}</el-button> -->
              <p class="su_tip">{{$t('register.reg_success_4')}}</p>
            </li>
            <!-- <li @click="toPage('home')">
              <h3><i class="el-icon-right"></i>{{$t('i18nn_6416685506bcd152')}}</h3>
              <p class="su_tip">随便逛逛，去网站首页，购买厂家商品~</p>
            </li> -->
          </ul>

					<!-- <p class="su_tip">提示：厂家认证后，方可交易！商户认证会有更多惊喜等着你哦！</p>
					<div class="reg_btn_con">
						<el-button type="primary" style="width: 200px;height: 48px;font-size: 16px;" @click="toPage('EnterpriseAuthentication')">{{$t('i18nn_4f5bb4ff8b3d804b')}}</el-button>
					</div> -->

					<div class="reg_tipMsg">
						<span class="reg_red">{{$t('i18nn_5e31c5dc9f86182e')}}</span>
						<span>{{$t('register.reg_success_5')}}</span>
					</div>
				</div>
			</div>
		</el-col>

	</el-row>
	</div>
</template>

<style lang='less' scoped>
// @import url(../../assets/css/module/login.less);
.registerCon {
	.registerSuCon {
		.registerSu {
			padding: 50px;
			width: 620px;



			h3 {
				font-size: 30px;
				font-weight: 600;
				color: #000;
				line-height: 44px;
				/* text-align: center; */
				.icon_gou {
					display: inline-block;
					width: 60px;
					height: 60px;
					background: url(../../assets/images/login/icon_success.png) no-repeat center center;
					background-size: 60px;
				}
			}
			.reg_msg {
				line-height: 44px;
				span {
					display: inline-block;vertical-align: middle;
				}
			}

			.reg_phone {
				color: #FF0000;
				line-height: 44px;
				display: inline-block;
				vertical-align: middle;
			}
			.su_tip {
				font-size: 16px;
				color:#666;
				padding-left: 0;
				/* text-align: center; */
				/* margin-top: 40px; */
			}
			.reg_btn_con {
				text-align: center;
				margin-top: 80px;
			}

			.reg_tipMsg {
				color:#666;
				font-size: 13px;
				line-height: 18px;
				margin-top: 200px;
			}
			.reg_red {
				color:#FF0000;
			}
      .way-list-tit  {
        color: #333;
        font-size: 30px;
        margin: 10px 0;
        margin-top: 30px;
        font-style: italic;
      }
      .way-list {
        margin-top: 40px;
        li {
          margin: 20px 0;

          border-bottom: 1px solid #e5e5e5;;
          cursor: pointer;
          &:hover {
            font-style: italic;
          }

          h3 {
            text-align: left;
            font-size: 24px;
            color:#333;
          }
          &:nth-child(1) {
            margin-left: 0px;
          }
          &:nth-child(2) {
            margin-left: 50px;
          }
          /* &:nth-child(3) {
            margin-left: 100px;
          } */
          /* &:nth-child(4) {
            margin-left: 150px;
          } */
          &:nth-child(4) {
            margin-left: 50px;
          }
        }

      }
		}
	}
}



</style>

<script>
  export default {
  	name:"registerSuccess",
  	//meta信息seo用
	  // metaInfo: {
	  //   title: '互易天下-厂家共享平台-注册成功', // set a title
	  //   // meta: [{                 // set meta
	  //   //   name: 'description',
	  //   //   content: '互易天下-厂家共享平台-注册成功'
	  //   // }],
	  // },
    data(){
			return{
				UserInfo:this.$store.getters.getUserInfo
			}
	},
	created() {
	    // console.log("created");
	    // console.log(this);
	    this.$parent.loginTitle = this.$t('register.reg_success_6');
	    // this.init();
	  },
	mounted() {
	    console.log("mounted",this.$store.getters.getUserInfo);
	    // this.init();
	},
	methods:{


	    init(){


	    },
	    toPage(urlName){
	    	this.$router.push({name: urlName});
	    }
	},
  }
</script>
